import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { capitalize } from "../utils/string";

// @ts-ignore
import cnCn from "./privacy-documents/CN - cn - Privacy Policy, 240606.pdf";
// @ts-ignore
import cnEn from "./privacy-documents/CN - en - Privacy Policy, 240606.pdf";
// @ts-ignore
import de from "./privacy-documents/DE - Privacy Policy, 240606.pdf";
// @ts-ignore
import enEu from "./privacy-documents/EU - Privacy Policy, 240606.pdf";
// @ts-ignore
import enUk from "./privacy-documents/UK - Privacy Policy, 240606.pdf";
// @ts-ignore
import enUs from "./privacy-documents/US - Privacy Policy, 240606.pdf";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" rowGap={1}>
      <Button component={Link} to={de} target="_blank">
        DE - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
      <Button component={Link} to={enEu} target="_blank">
        EU - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
      <Button component={Link} to={enUk} target="_blank">
        UK - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
      <Button component={Link} to={enUs} target="_blank">
        US - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
      <Button component={Link} to={cnCn} target="_blank">
        CN - cn - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
      <Button component={Link} to={cnEn} target="_blank">
        CN - en - {capitalize(t("auth.signup.privacyPolicy"))}
      </Button>
    </Stack>
  );
};

export default PrivacyPolicy;
