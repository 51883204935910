import { TypographyProps, styled, SxProps, Grid } from "@mui/material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Fragment, ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import Header, { HeadingContext, useHeadingProvider } from "./Header";
import TableOfContents from "./Contents";
import rehypeRaw from "rehype-raw";
import rehypeAttrs from "rehype-attr";
import LinkRenderer from "../../components/LinkRenderer";
import NoSpacingMarkdown from "../../components/Markdown";
import { VimeoPlayer } from "../../components/VimeoPlayer";

const HelpArticle = styled("article")`
  display: flex;
  flex-flow: column;
  row-gap: ${({ theme }) => theme.spacing(5)};

  max-width: ${({ theme }) => theme.spacing(100)};
  margin: auto;

  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.palette.secondary.main};
    margin-bottom: ${({ theme }) => theme.spacing(0)};

    &::after {
      content: none;
    }
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const HelpSection = styled("section")`
  display: flex;
  flex-flow: column;
  row-gap: ${({ theme }) => theme.spacing(4)};
`;

const DualColumn = styled("div")`
  display: grid;
  margin-top: ${({ theme }) => theme.spacing(5)};
  grid-template-columns: auto 1fr;
  column-gap: ${({ theme }) => theme.spacing(3)};
  row-gap: ${({ theme }) => theme.spacing(2)};
`;

const Number = styled("span")<{ ckey: string }>`
  display: inline-block;
  &::before {
    counter-increment: ${({ ckey }) => ckey};
    content: counter(${({ ckey }) => ckey}) ".";
  }
`;

const StyledImage = styled("img")`
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const Image = ({ src, ...props }: Parameters<typeof StyledImage>[0]) => {
  const { i18n } = useTranslation();
  const image = useRef<HTMLImageElement>(null);
  const source =
    src && `${window.location.origin}/img/help/${i18n.language}/${src}`;
  const fallbackSource = src && `${window.location.origin}/img/help/en/${src}`;
  return (
    <StyledImage
      ref={image}
      src={source}
      {...props}
      onError={() => {
        if (image.current && fallbackSource) image.current.src = fallbackSource;
      }}
    />
  );
};

const getKeyAsObject = (t: TFunction, tkey: string) =>
  Object.values(
    t(tkey, {
      joinArrays: "  \n",
      returnObjects: true,
    })
  );

interface Step {
  title: string;
  text: string;
}
type Steps = Array<Step>;

const TextSteps = ({
  tkey,
  sx,
  children,
}: {
  tkey: string | Steps;
  sx?: SxProps<any>;
  children?: ReactNode | ((i: number, step: Step, tkey: string) => ReactNode);
}) => {
  const { t } = useTranslation();

  const values: Array<{ title: string; text: string }> =
    typeof tkey === "string" ? getKeyAsObject(t, tkey) : tkey;

  return (
    <DualColumn sx={{ gridTemplateColumns: "auto 1fr", ...sx }}>
      {values
        .filter((step) => step.title?.trim() || step.text?.trim())
        .map((step, i) => (
          <Fragment key={i}>
            <Typography fontWeight="bold">{step.title}</Typography>
            <Stack direction="column" rowGap={1}>
              {step.text && (
                <NoSpacingMarkdown linkTarget="_blank">
                  {step.text}
                </NoSpacingMarkdown>
              )}
              {typeof children === "function"
                ? children?.(i, step, `${tkey}.step${i + 1}`)
                : children}
            </Stack>
          </Fragment>
        ))}
    </DualColumn>
  );
};

const SimpleTextSteps = ({
  tkey,
  children,
  sx,
}: {
  tkey: string | string[];
  sx?: SxProps<any>;
  children?: (step: number, text: string) => ReactNode;
}) => {
  const { t } = useTranslation();

  const values: string[] =
    typeof tkey === "string" ? getKeyAsObject(t, tkey) : tkey;

  return (
    <Stack component="ol" rowGap={1} sx={sx}>
      {values
        .filter((step) => step?.trim())
        .map((step, i) => (
          <li key={i}>
            <Stack direction="column" rowGap={0.2}>
              <NoSpacingMarkdown linkTarget="_blank">{step}</NoSpacingMarkdown>
              {children?.(i, step)}
            </Stack>
          </li>
        ))}
    </Stack>
  );
};

const NumberedSteps = ({ tkey }: { tkey: string | Steps }) => {
  const { t } = useTranslation();

  const values: Array<{ title: string; text: string }> =
    typeof tkey === "string" ? getKeyAsObject(t, tkey) : tkey;

  return (
    <Stack component="ol" direction="column" rowGap={1}>
      {values
        .filter((step) => step.title?.trim() || step.text?.trim())
        .map((step, i) => (
          <li key={i}>
            <Stack direction="column" rowGap={0.2}>
              {step.title && (
                <Typography fontWeight="bold">{step.title}.</Typography>
              )}
              <NoSpacingMarkdown linkTarget="_blank">
                {step.text}
              </NoSpacingMarkdown>
            </Stack>
          </li>
        ))}
    </Stack>
  );
};

const Markdown = ({ tkey }: { tkey: string }) => {
  const { t } = useTranslation();
  const host = window.location.origin;

  return (
    <Box>
      <NoSpacingMarkdown
        linkTarget="_blank"
        components={{ a: LinkRenderer }}
        rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: "attr" }]]}
      >
        {t(tkey, {
          joinArrays: "  \n",
          host,
        })}
      </NoSpacingMarkdown>
    </Box>
  );
};

const P = ({ tkey, ...props }: { tkey: string } & TypographyProps) => {
  const { t } = useTranslation();
  const host = window.location.origin;

  return (
    <Typography {...props} variant="body1">
      {t(tkey, {
        joinArrays: "  \n",
        host,
      })}
    </Typography>
  );
};

const ImageRow = ({
  counterKey,
  tkey,
  srcs,
}: {
  counterKey: string;
  tkey: string;
  srcs: string[];
}) => {
  return (
    <>
      <Box>
        <Typography fontWeight="bold" whiteSpace="nowrap" component="div">
          <Number ckey={counterKey} />{" "}
          <P tkey={`${tkey}.title`} fontWeight="bold" display="inline-block" />
        </Typography>
        <Markdown tkey={`${tkey}.text`} />
      </Box>
      <Stack direction="column" rowGap={2}>
        {srcs.map((src) => (
          <Image src={src} alt={counterKey} key={src} />
        ))}
      </Stack>
    </>
  );
};

const Help = () => {
  const { t } = useTranslation();

  const provider = useHeadingProvider();

  const installationStepCounter = "installationStepCounter";

  const webhookStepsArray: Array<string> = Object.values(
    t("setup.payment.stripe.webhookInfo", {
      stripeSetupWebhookUrl: process.env.REACT_APP_STRIPE_SETUP_WEBHOOK_URL,
      stripeApiWebhookUrl: `${process.env.REACT_APP_API_BASE_URL}/api/stripe-webhook`,
      returnObjects: true,
    })
  );

  const webhookSteps = webhookStepsArray.map((step) =>
    step.replace(/^ *\d+\. */, "")
  );

  useEffect(() => {
    const hash = window.location.hash;
    setTimeout(() => {
      window.location.hash = "";
      window.location.hash = hash;
    }, 500);
  }, []);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
    return () => {
      // @ts-ignore
      document.documentElement.style.scrollBehavior = null;
    };
  }, []);

  return (
    <Grid item xs={12}>
      <Stack rowGap={15}>
        <Paper>
          <Stack rowGap={4} p={10}>
            <HeadingContext.Provider value={provider}>
              <HelpArticle>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    {t("help.appName")}
                  </Typography>
                  <Typography variant="h1">{t("help.title")}</Typography>
                </Box>
                <Box component="section" sx={{ mb: 4 }}>
                  <TableOfContents headings={provider.headingsTree} />
                </Box>
                <HelpSection id="installation">
                  <Header title={t("help.section.installation.title")}>
                    <VimeoPlayer url={t("help.section.installation.video")} />
                    <P tkey={"help.section.installation.requirementsPreface"} />
                    <Markdown tkey={"help.section.installation.requirements"} />

                    <DualColumn sx={{ counterReset: installationStepCounter }}>
                      <ImageRow
                        counterKey={installationStepCounter}
                        tkey={"help.section.installation.steps.step1"}
                        srcs={["start.png", "login.png"]}
                      ></ImageRow>
                      <ImageRow
                        counterKey={installationStepCounter}
                        tkey={"help.section.installation.steps.step2"}
                        srcs={["createCompany.png"]}
                      ></ImageRow>
                      <ImageRow
                        counterKey={installationStepCounter}
                        tkey={"help.section.installation.steps.step3"}
                        srcs={["createMachine.png"]}
                      ></ImageRow>
                      <ImageRow
                        counterKey={installationStepCounter}
                        tkey={"help.section.installation.steps.step4"}
                        srcs={["createMenuEmpty.png"]}
                      ></ImageRow>
                      <ImageRow
                        counterKey={installationStepCounter}
                        tkey={"help.section.installation.steps.step5"}
                        srcs={["setupPayment.png"]}
                      ></ImageRow>
                    </DualColumn>
                  </Header>
                </HelpSection>
                <HelpSection>
                  <Header title={t("help.section.dashboard.title")}>
                    <Image src="dashboard.png" alt="dashboard" />
                    <Markdown tkey="help.section.dashboard.description" />

                    <Header title={t("help.section.dashboard.addWidget.title")}>
                      <Image
                        src="createWidgetButton.png"
                        alt="addWidget"
                        sx={{ width: "auto" }}
                      />
                      <Image
                        src="createWidget.png"
                        alt="addWidget"
                        sx={{ width: "auto" }}
                      />
                      <NumberedSteps tkey="help.section.dashboard.addWidget.steps" />
                    </Header>
                  </Header>
                </HelpSection>
                <HelpSection>
                  <Header title={t("help.section.coffeeMenus.title")}>
                    <VimeoPlayer url={t("help.section.coffeeMenus.video")} />
                    <Image src="menuList.png" alt="coffeeMenus" />
                    <Markdown tkey="help.section.coffeeMenus.description" />
                    <Header
                      title={t("help.section.coffeeMenus.createMenu.title")}
                    >
                      <Image
                        src="createMenuButton.png"
                        alt="createCoffeeMenu"
                        sx={{ width: "auto", maxWidth: "350px" }}
                      />

                      <SimpleTextSteps tkey="help.section.coffeeMenus.createMenu.steps">
                        {(i) =>
                          i === 0 && (
                            <Image
                              src={"createMenuFilled.png"}
                              alt="Create coffee menu popup"
                              sx={{ width: "auto" }}
                            />
                          )
                        }
                      </SimpleTextSteps>
                    </Header>
                    <Header
                      title={t("help.section.coffeeMenus.editMenu.title")}
                      rowGap={5}
                    >
                      <Image
                        src="editMenuButton.png"
                        alt="Go to menu button"
                        sx={{ width: "auto" }}
                      />
                      <P tkey="help.section.coffeeMenus.editMenu.goToMenu" />
                      <Image src="productList.png" alt="Coffee menu view" />
                      <Markdown tkey="help.section.coffeeMenus.editMenu.description" />
                      <Stack direction="column">
                        <P
                          tkey="help.section.coffeeMenus.editMenu.tutorial.title"
                          fontWeight="bold"
                        />
                        <TextSteps
                          tkey="help.section.coffeeMenus.editMenu.tutorial.steps"
                          sx={{ mt: 2 }}
                        />
                      </Stack>
                      <Markdown tkey="help.section.coffeeMenus.editMenu.descriptionOverview" />
                      <Stack direction="column">
                        <Markdown tkey="help.section.coffeeMenus.editMenu.descriptionMachine" />
                        <TextSteps
                          tkey="help.section.coffeeMenus.editMenu.machinesUsingMenu.tutorial.steps"
                          sx={{ mt: 2 }}
                        />
                      </Stack>
                    </Header>
                    <Header
                      title={t("help.section.coffeeMenus.editProducts.title")}
                      rowGap={4}
                    >
                      <Image
                        src="editProduct.png"
                        alt="Edit product popup"
                        sx={{ width: "auto" }}
                      />
                      <Markdown tkey="help.section.coffeeMenus.editProducts.description" />
                      <TextSteps
                        tkey="help.section.coffeeMenus.editProducts.form.steps"
                        sx={{ mt: 0 }}
                      />
                    </Header>
                    <Header
                      title={t("help.section.coffeeMenus.configureMode.title")}
                      rowGap={4}
                    >
                      <Stack direction="column">
                        <Markdown tkey="help.section.coffeeMenus.configureMode.description" />
                        <SimpleTextSteps tkey="help.section.coffeeMenus.configureMode.process.steps" />
                        <Image
                          src="menuOverview.png"
                          alt="Edit product popup"
                          sx={{ width: "auto" }}
                        />
                        <TextSteps tkey="help.section.coffeeMenus.configureMode.modes.steps" />
                      </Stack>
                    </Header>
                  </Header>
                </HelpSection>
                <HelpSection>
                  <Header
                    title={t("help.section.coffeeMachine.title")}
                    rowGap={4}
                  >
                    <Image
                      src="machineList.png"
                      alt="Coffeemachines"
                      sx={{ width: "auto" }}
                    />
                    <Markdown tkey="help.section.coffeeMachine.description" />
                    <Stack direction="column">
                      <P
                        tkey="help.section.coffeeMachine.tutorial.title"
                        fontWeight="bold"
                      />
                      <TextSteps
                        tkey="help.section.coffeeMachine.tutorial.steps"
                        sx={{ mt: 2 }}
                      />
                    </Stack>

                    <Header
                      title={t("help.section.coffeeMachine.machines.title")}
                      rowGap={4}
                    >
                      <Image
                        src={"machineOverview.png"}
                        alt="Coffee machine"
                        sx={{ width: "auto" }}
                      />
                      <Markdown tkey="help.section.coffeeMachine.machines.description" />
                      <Stack direction="column">
                        <P
                          tkey="help.section.coffeeMachine.machines.tutorial.title"
                          fontWeight="bold"
                        />
                        <TextSteps
                          tkey="help.section.coffeeMachine.machines.tutorial.steps"
                          sx={{ mt: 2 }}
                        />
                      </Stack>
                      <Header
                        title={t(
                          "help.section.coffeeMachine.machines.configuration.title"
                        )}
                        rowGap={4}
                      >
                        <Image
                          src={"machineConfiguration.png"}
                          alt="Configure coffee machine"
                          sx={{ width: "auto" }}
                        />
                        <Markdown tkey="help.section.coffeeMachine.machines.configuration.description" />
                        <Stack direction="column">
                          <P
                            tkey="help.section.coffeeMachine.machines.configuration.tutorial.title"
                            fontWeight="bold"
                          />
                          <TextSteps
                            tkey="help.section.coffeeMachine.machines.configuration.tutorial.steps"
                            sx={{ mt: 2 }}
                          />
                        </Stack>
                        <Header
                          title={t(
                            "help.section.coffeeMachine.machines.configuration.mode.open.title"
                          )}
                          rowGap={4}
                        >
                          <Box>
                            <Markdown tkey="help.section.coffeeMachine.machines.configuration.mode.open.description" />
                            <SimpleTextSteps tkey="help.section.coffeeMachine.machines.configuration.mode.open.steps">
                              {(i) =>
                                i === 2 && (
                                  <Image
                                    src={"machineConfigurationOpen.png"}
                                    alt="Save mode open"
                                    sx={{ mt: 2 }}
                                  />
                                )
                              }
                            </SimpleTextSteps>
                          </Box>
                        </Header>
                        <Header
                          title={t(
                            "help.section.coffeeMachine.machines.configuration.mode.prepaid.title"
                          )}
                          rowGap={4}
                        >
                          <Box>
                            <Markdown tkey="help.section.coffeeMachine.machines.configuration.mode.prepaid.description" />
                            <SimpleTextSteps tkey="help.section.coffeeMachine.machines.configuration.mode.prepaid.steps" />
                          </Box>
                        </Header>
                        <Header
                          title={t(
                            "help.section.coffeeMachine.machines.configuration.mode.authorizedAccess.title"
                          )}
                          rowGap={4}
                        >
                          <Box>
                            <Markdown tkey="help.section.coffeeMachine.machines.configuration.mode.authorizedAccess.description" />
                          </Box>
                        </Header>
                        <Header
                          title={t(
                            "help.section.coffeeMachine.machines.configuration.mode.payment.title"
                          )}
                          rowGap={4}
                        >
                          <Box>
                            <Markdown tkey="help.section.coffeeMachine.machines.configuration.mode.payment.description" />
                          </Box>
                        </Header>
                        <Header
                          title={t(
                            "help.section.coffeeMachine.machines.configuration.mode.custom.title"
                          )}
                          rowGap={4}
                        >
                          <Box>
                            <Markdown tkey="help.section.coffeeMachine.machines.configuration.mode.custom.description" />
                          </Box>
                        </Header>
                      </Header>
                    </Header>
                  </Header>
                </HelpSection>
                <HelpSection>
                  <Header title={t("help.section.setup.title")} rowGap={4}>
                    <Image src="setupLocation.png" alt="Setup" />
                    <Markdown tkey="help.section.setup.description" />

                    <Header
                      title={t("help.section.setup.locations.title")}
                      rowGap={4}
                    >
                      <Stack direction="column">
                        <P
                          tkey="help.section.setup.locations.tutorial.title"
                          fontWeight="bold"
                        />
                        <TextSteps
                          tkey="help.section.setup.locations.tutorial.steps"
                          sx={{ mt: 2 }}
                        />
                      </Stack>
                    </Header>
                    <Header
                      title={t("help.section.setup.payment.title")}
                      rowGap={4}
                    >
                      <Image src="setupPayment.png" alt="Payment" />
                      <Header
                        title={t(
                          "help.section.setup.payment.addProvider.title"
                        )}
                      >
                        <Markdown tkey="help.section.setup.payment.addProvider.description" />
                        <VimeoPlayer
                          url={t("help.section.setup.payment.video")}
                        />
                        <TextSteps
                          tkey="help.section.setup.payment.addProvider.parts"
                          sx={{
                            mt: 0,
                            gridTemplateColumns: "1fr",
                            rowGap: 0,
                            "& > .MuiTypography-root": { mt: 2 },
                          }}
                        >
                          {(i) =>
                            i === 1 && (
                              <SimpleTextSteps
                                tkey={webhookSteps}
                                sx={{ mt: 0 }}
                              />
                            )
                          }
                        </TextSteps>
                      </Header>
                    </Header>
                    <Header title={t("help.section.setup.operators.title")}>
                      <Image src="setupOperators.png" alt="Operators" />
                      <Markdown tkey="help.section.setup.operators.description" />
                    </Header>
                    <Header title={t("help.section.setup.creditTopUps.title")}>
                      <VimeoPlayer
                        url={t("help.section.setup.creditTopUps.video")}
                      />
                      <Markdown tkey="help.section.setup.creditTopUps.description" />
                      <Image src="setupCreditTopUp.png" alt="Credit top ups" />
                    </Header>
                    <Header title={t("help.section.setup.company.title")}>
                      <Image src="setupCompany.png" alt="Company" />
                      <Markdown tkey="help.section.setup.company.description" />
                    </Header>
                  </Header>
                </HelpSection>
                <HelpSection>
                  <Header title={t("help.section.userManagement.title")}>
                    <Image src="users.png" alt="User management" />
                    <Markdown tkey="help.section.userManagement.description" />
                    <Stack direction="column">
                      <P
                        tkey="help.section.userManagement.tutorial.title"
                        fontWeight="bold"
                      />
                      <TextSteps
                        tkey="help.section.userManagement.tutorial.steps"
                        sx={{ mt: 2 }}
                      >
                        {(_, __, tkey) =>
                          tkey ===
                            "help.section.userManagement.tutorial.steps.step4" && (
                            <TextSteps tkey={`${tkey}.steps`} />
                          )
                        }
                      </TextSteps>
                    </Stack>
                    <Header title={t("help.section.setup.userGroups.title")}>
                      <Markdown tkey="help.section.setup.userGroups.description" />
                      <Image src="userGroups.png" alt="Usergroups" />
                    </Header>
                  </Header>
                </HelpSection>
              </HelpArticle>
            </HeadingContext.Provider>
          </Stack>
        </Paper>
      </Stack>
    </Grid>
  );
};

export default Help;
